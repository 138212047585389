<template>
  <div>
    <ed-funcionario :key="$root.$session.versao" />
  </div>
</template>

<script>
import EdFuncionario from '@/components/cadastro/funcionario/editar'

export default {
  name: "pageCadastroEmpresa",
  props: {},
  components: {EdFuncionario },
  mounted() {},
  created() {},
  data() {
    return {
    };
  },
  methods: {
  
  },
};
</script>